
.Profile {
  width: 100% !important;
  
 
}
.main-content
{
  /* margin-top: 1%; */
  /* height: 100%; */
  max-width: 100% !important;
  text-overflow: break-word;
  min-height: 100vh;
  max-height: auto !important;
   
}



.Profile-logo
{
  /*display: block;*/
  /*margin-left: auto;*/
 /* margin-right: auto;*/

  min-height: auto;
  display: block;
  margin-top: 5%;
  margin-left: 0px;
  float: left;
  
}
.prf_lg
{
  min-height: 260px !important;
  max-height: auto !important;
  background-color: #004466;
  
}

.pagebackground
{

 // background-color:#004466 !important;
  
 background-color: orange !important;
 width: 100% !important;
//   text-overflow: break-word;
//   overflow: auto;
//   padding-bottom: 50px !important;
//  // margin-bottom: 15px !important;

  // padding-left:2%;
  // padding-right: 2%;
  // padding-top: 2%;
  
}

 
.divbackground
{
  
  background-color:  #004466;
  text-overflow: break-word;
  overflow: auto;
  padding-left:2%;
  padding-right: 2%;
  //padding-top: 2%;
  //padding-bottom: 2%;
  //margin-top: 2%;
  
}


.cpLogo
{
  
  background-color:  #004466;
  // background-color: orange !important;
  text-overflow: break-word;
  overflow: auto;
  padding-bottom: 20px !important;
  margin-bottom: 15px !important;

  padding-left:2%;
  padding-right: 2%;
  padding-top: 2%;
  
  overflow: auto; 
  
}

.shunshine_publication_background
{
  
  background-color:#004466 !important;
  // text-overflow: break-word;
  // // padding-bottom: 50px !important;
  // // margin-bottom: 15px !important;  
  // overflow: auto; 
  // padding-left:1%;
  // padding-right:1%;
  // padding-top:1%;
}

.pic_cont
{
  height: 250px;
  width: 220px;
  background-color: #004466;
  margin-top: 30px !important;
  border-radius: 60%;
  float: right;
}
.Profile-Logos
{
  height: 260px;
  width: 260px;
  margin-top: -10px;
  //margin-right: -10px;
  border-radius: 200%;
 // position: absolute !important;
  //position: relative !important;
  //float: right !important;
  //margin-left:auto;
  //left: 75.5%;
  
  right: 0% !important;
  // padding-right: 0px !important;
  // margin-right: 0px !important;
  
}

.lelogo
{
  position: absolute;
  /*transform: translate(305%, -121%);*/
  //margin-left: -12px;
 // margin-top: -12px;
  //margin-bottom: 12px;
  height: 145px;
  width: 200px;
  border-radius: 0px 6px 138px 6px;
  background-color: white;
 margin-top: 2%;  
}

.riglogo1
{
  
  float: right;
  text-align: right;
  height: auto !important;
  width: auto !important;
  background-color:transparent !important;
 // background-color: orange;
  color: black;
  border: none;
  position: relative;
  left: 0;
  right: 0;
  -o-text-overflow:break-word;
  // text-overflow: break-word !important;
  // word-break: break-all !important;
 // margin-top: -15px;
   //margin-right: -10px;
  
}

/* .riglogo1 .text-mute1
{
  line-height: 0.5;
  font-size: 12px;
}
.riglogo1 h3, p
{
  color: white;
  text-align: right;
  float: right;
  
} */

// .text-mute1
// {
//   font-size: 11px !important;
//   // color: orange !important;
//   text-align: right;
//   float: right;
//   line-height: .1 !important;
// //  height: 10px;
//  // margin-top: -5px;
 
// //  word-break: break-all;
// //  text-overflow:break-word;
// //    height: auto;
// //    -o-text-overflow:break-word;
 

// }


.riglogo1 h3
{
  width: 100%;
 
  //font-family: "Georgia", monospace;
  font-size: 20px;
  text-align: right;
  float: right;
  height: 28px;
  
 
}

.riglogo1 h4
{
  width: 98%;
 
  //font-family: "Georgia", monospace;
  font-size: 15px;
  text-align: right;
  float: right;
  height: 30px;
  margin-bottom: 10px;
}

.riglogo1 pp
{
  -o-text-overflow:break-word;
  //font-family: "Georgia", monospace;
  // font-size: 12px;
  // text-align: right;
  // float: right;
  // line-height: .1 !important;
  // color: white !important;
  // min-height: 2px;
  // max-height: auto !important;
  // word-break: break-all !important;
  //  text-overflow:break-word !important;

  display:inline-block;
  height: auto;
  min-height: 13px !important;
  margin-top: 2px;
  background-color:transparent;
  width: 90%;
  float: right;
  font-size: 12px;
  color: white !important;
 // line-height: .8 !important;
  -o-text-overflow:break-word !important;
  word-break: break-all !important;
  // word-break: break-all !important;
  // text-overflow:break-word !important;
}
.riglogo1 pp a
{
  font-size: 12px;
 font-weight:normal !important;
  color: white;
  text-decoration: none;
}

.riglogo1 pp a:hover
{
  color: white !important;
  text-decoration: underline !important;

 }
.riglogo1 .mText1
{
  //font-family: "Georgia", monospace;
  font-size: 14px;
}








.bDetails
{
   margin-top: 0px;
   //display: flex;
   flex-direction: column;  
   display:block;
   //float:left;
   position: absolute;
   text-overflow: break-word; 
  //  border-radius: 0 0px 138px 0;
   /* align-items: left; */
   width: auto;
  max-width: 95%; 
  min-width: 95%;
  height: auto;
  min-height: 260px;
  
    
  // padding-bottom: 100px !important;
  
}

//  .bDetails p, h5
// {
//   color: black;
//   float: left;
//   text-align: left;
//  // font-family: "Georgia", monospace;
//   font-size: 12px;
//   font-weight: bold;
//   min-width: 70%;
//   max-width: 70%;
  
//   // padding-top: 8px;
// padding-bottom: 6px;
 
  
// } 

.bDetails p
{
  color: black;
  float: left;
  text-align: left;
  //font-family: "Georgia", monospace;
  // line-height: 1.3 !important;
  font-size: 12px;
  font-weight: bold;
  min-width: 65% !important;
  max-width: 65% !important;
  height: auto;
  margin-bottom:2px;
  display: block;
  
} 

.bDetails h5
{
  min-width: 60% !important;
  max-width: 60% !important;  
  color: #004466;
  float: left;
  text-align: left;
  //font-family: "Georgia", monospace;
  font-size: 17px;
 // margin-top: 10px;
  height: 20px;
  font-weight: bolder;
  display: block;
  
}

.bDetails a { color: #069 !important;}

.rating
{
  color: #004466;
  font-size:large !important;
  font-weight: bolder;
   
}

.bDetails imag{ 
   border-radius: 12px 138px 138px 12px !important;
   height: auto;
   min-height: 250px;
  //position: relative !important;
  //float: right !important;
  //text-align: right !important;
 //width: auto;
 max-width:98.5%;
 min-width:98.5%;
  display:block;
  text-align: right;
  position:absolute !important;
  //display:flexbox !important;
  // margin-top: -20px;
  // background-color:orange;  
  
 }

// .card
// {
//   min-height: 300px;
//   max-height: auto !important;
//    background-color: transparent !important;
//     border: none !important;
  
// }

.prf_lg .row .card
{
  background-image:url(../Assets/Images/t.jpg);
  background-repeat: no-repeat;
  background-size: cover; 
  padding-top: 10px;
  padding-bottom: 10px;
}




 .cDetails .card
{
  border: none;
  border-radius: 12px 138px 138px 12px !important;
  background-color: white ;
  height: auto ;
  min-height: 260px !important;
  max-height: 260px !important;
  max-width: 98% !important; 
  min-width: 98% !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
  /* margin-top: -155px; */
   margin-left: 1.2%;
   margin-top: 20px;
} 

.headings
{
 // font-family: "Georgia", monospace;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
   text-align: left !important;
   
}
.heh1
{
  margin-top: -30px;
}
.mDetails
{
  float: left;
  text-align: left;
  align-items: left;
 
}
.mDetails p
{
  float: left;
  //font-family: "Georgia", monospace;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: left;
  

}

.bcolor1 
 {
// font-family: "Georgia", monospace;
  font-size: 12px;
  font-weight: bold;
  color: black;
   
  /* height: auto !important; */
  min-height: 50vh !important;

  display: block !important;
   background-color: orange !important;
   
     border-radius: 50px !important; 
    
   
    box-shadow: inset !important;
   border-color: black !important;
   
}



.bcolor 
 {
 //font-family: "Georgia", monospace;
  font-size: 12px;
  font-weight: bold;
  color: black;
   height: auto !important;
   min-height: 10vh;
   text-overflow: break-word; 

   background-color: white !important;
   display: inline-block;
   
   /* border: 1px !important;
    min-height: 10vh;
    */
   /* box-shadow: inset !important;
   border-color: black !important;
   */
}



.nDetails p
{
  float: left;
 // font-family: "Georgia", monospace !important;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: left;
  align-items: left;
  /* line-height: 0.9; */
   min-height: 10px;
   height: auto !important;
   text-overflow: break-word; 
   
}

.nDetails p a {color: #069 !important;}

.h-20 {
//   height: auto; min-height:5px; 
//   display      : -ms-flexbox;
//   display      : flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap    : wrap;
   margin-top : -15px;
   margin-bottom  : -15px;
 }


.sDetails p
{
  float: left;
 // font-family: "Georgia", monospace !important;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: left;
  align-items: left;
  line-height: 2.3 !important;
  
}
hr
{
  color: black;
  transform: translate(0%, -200%);
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.s_Details p
{
  float: left;
 // font-family: "Georgia", monospace;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: left;
  align-items: left;
  line-height: 1.2 !important;
}

