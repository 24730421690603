.biography{

  width: 100% !important;

}

.main_col h1
{
  font-size: 28px;
  color: #01345C;
//  font-family: "Georgia", monospace;
   margin-left: 0%;

}

.imgCont
{
  
  border-color: 2px solid black;
  background-color: #01345C !important;
  height: auto;
  min-height: 67vh;
  width:auto !important;
  min-width: 35vh !important;
  //font-family: "Georgia", monospace;
  margin-left: 0%;
  float: left;
  padding-bottom: 10px;
  padding-right: 5px !important;
  padding-right: 5px !important;
}


.imgData
{
  color: white; 
  font-size: 13px;
  /* text-align: justify;
  font-family: sans-serif;   */
  /* min-width: 300px;
  max-width: 300px;
   */
  width: auto !important;
  /* padding-left: 10px !important; */
  /* padding-right: 10px !important; */
  /* min-width: 200px !important; */
  /* max-width: 200px !important; */
  -o-text-overflow:break-word;
  float:left;
  
}
.imgData p 
{  
 
  text-align: left !important;
  float: left !important;
 // font-family: "Georgia", monospace;
  line-height: 1.1;
  font-size: 13px;
   text-overflow:break-word;
   height: auto;
   min-width: 170px !important;
   /* max-width: 170px !important; */
   -o-text-overflow:break-word;
   height: auto ;  
   margin-bottom: 7px !important;   

}

.imgData a 
{
  color: #41A2FF !important;
  word-break: break-all;
}


.colDatas
{
  color: black !important;
  font-size: 15px;
  text-align: justify;
  /* font-family: sans-serif; */
  font-weight: lighter;
 // font-family: "Georgia", monospace;

}
.kol_pic
{
  height: 30px;
  width: 30px;
}
.pic_cont
{
  height: 170px;
  width: 150px;
  background-color: white;
  margin-top: 10px !important;
  border-radius: 60%;
}
.biography-Logo
{
  height: 160px;
  width: 160px;
  text-align: center;
  mmargin-top: 5px !important;
  border-radius: 60%;
}



.biography-backoptiopn
{
  height: 40px;
  width: 100%;
  text-align:right !important;
  /* background-color:#F9F9F9; */
  padding-right: -1% !important;
  font-weight: bold !important;
  // margin-top: 4% !important;
  font-size: 16PX !important;

  
}

.biography-backoptiopn a
{
  color: #41A2FF !important;
 
}

