body{
    color: #133861;
    padding: 0 !important;
}
.primary{
    background: #f2b632 !important;
}

.secondary{
    background: #242c43 !important;
    color: #ffffff !important;
}
.bg-thrid{
    background: #a90007 !important;
    color: #ffffff !important;
}
.bg-fourth{
    background: #133861 !important;
    color: #ffffff !important;
}
.bodyPart{
    min-height: 450px;
}

