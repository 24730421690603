@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --brand: #A90007;
  --secbrand: #133861;
  --dark: #092032;
  --body: #133861;
  --border: rgba(0, 0, 0, 0.08);
  --shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  --background: #f9f9f9;
}

body {
  // font-family: "Barlow", sans-serif !important;
  //
  color: var(--body);
  line-height: 1.7;
  // background-color:#F9F9F9 !important;
}

.Client-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100px;
  width: 100%;
}

.header-nav {
  width: 100%;
  box-shadow: 3px 2px 4px 0px #e6e6e6;
}

.navbar {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: #ffffff !important;

  //   background-color: #FFFFFF;
  min-height: 80px !important;

  //   width: 100%;
  box-shadow: 3px 2px 4px 0px #e6e6e6;
}

.navbar-brand img {
  // position: fixed;
  // margin-top: -30px;
  height: 65px;
  width: auto;
  // margin-left: -0.3%;
}

.navbar-nav {
  //padding-right: 20px;

  margin-right: 2%;
  margin-top: -1%;
}

.navbar-nav .nav-link {
  font-size: 12px !important;
  font-weight: normal !important;
  //padding-right: 25px !important;
}

.navbar-nav button,
.navbar-nav button:hover {
  color: white !important;
  background-color: #A51E22;
  font-weight: bold !important;
  margin-top: 7% !important;
}

.navbar-nav button:hover {
  color: white !important;
  background-color: #A51E22;
}

.navbar-nav a:hover {
  color: red;
}

#mainClientDashboard {
  //  padding-top: 15px !important;
  color: black !important;
  // padding-left: 62px !important;
  // padding-right: 32px !important;
  filter: blur(0.1px);
  -webkit-filter: blur(0.1px);
  // background-color: var(--background);
  // background-color: #F9F9F9 !important;
}

table thead {
  font-size: 13px !important;
}

table tbody {
  font-size: 12px !important;
  color: #000000 !important;
}

td {
  margin-left: auto;
  margin-right: auto;
}

.searching {
  float: right !important;
  align-content: right !important;
}

.searching input[type="search"] {
  border-bottom-color: #02365f !important;
  height: 40px !important;

  // min-width: 200px !important;
}

.searching input,
.searching input:hover,
.searching input:active,
.searching input:focus {
  border-top: none !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #02365f !important;
  outline: none !important;
  outline-color: transparent !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  background-color: transparent !important;
}

.searching i {
  color: #02365f !important;
  font-weight: lighter !important;
  font-size: 14px !important;
}

.card {
  border-radius: 10px !important;
}

.pieCard {
  min-height: 79.8vh !important;
  // max-height: 79.8vh !important;
  height: auto !important;
  // background-color:#fff !important;
  // border:gainsboro !important;
  //border-style: solid !important;
  //padding:3%;
  // border-width: 1px !important;
}

.pCounts p {
  padding-top: 0px !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.pCounts h1 {
  padding-top: 1px !important;
  font-size: 40px !important;
  position: fixed;
}

.pieHead {
  font-size: 14px !important;
  text-align: center !important;
  font-weight: normal !important;
  color: #069;
}

.pieImg {
  width: 400px !important;
  margin-top: 30px !important;
}

.ddetails {
  min-height: 130px !important;
  max-height: 130px !important;
}

.ddetails h6 {
  position: fixed;
}

.hdet {
  //position: fixed;
  margin-top: -10px;
  padding-left: 10px;
  font-size: 35px;
  color: #02365f;
  // background-color: #A90007 !important;
  display: inline-block;
}

.pdet {
  //position: fixed;
  padding-left: 4%;
  padding-top: 15px;
  font-size: 15px;
  // background-color: olive !important;
  display: inline-block;
  font-weight: 600;
}

.ddetails hr {
  margin-top: 13%;
  height: 0.1px !important;
  margin-bottom: 4% !important;
}

.breadcum p {
  font-size: 15px !important;
  font-weight: bold;
  float: right;
  // padding-right: 2%;
}

.breadcum p a {
  text-decoration: none;
  color: #41a2ff !important;
}

.breadcum h4 p {
  padding-top: 2%;
}

.breadcum h4 {
  // padding-left: 1%;
  // padding-right: 1%;
  //height: 20px;
  margin-top: 4px !important;
}

.Client_profile_pic {
  margin-top: -10px !important;
  border-radius: 50%;
  max-height: 70px;
  min-height: 70px;
  min-width: 70px;
  max-width: 70px;
}

.hexagon {
  margin-top: 25px !important;
  width: 69px;
  height: 40px;
  background-color: #A51E22 !important;
  border-color: #A51E22 !important;
  position: relative;
  display: inline-block;
}

.hexagon::before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 24px solid #A51E22;
  // border-color: orange !important;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  position: absolute;
  //background-color: #069;
  top: -24px;
}

.hexagon::after {
  content: " ";
  width: 0;
  height: 0;
  position: absolute;
  bottom: -24px;
  border-top: 24px solid #A51E22;
  //border-color:green !important;
  // background-color: #069;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
}

.hexL {
  text-align: center;
  //position: absolute;
  vertical-align: middle !important;
  font-size: 20px;
  font-weight: bold;
  margin-top: auto;
  margin-left: auto;
  display: block;
  color: white;
  // padding-left: 35%;
  padding-top: 8%;
}

.butns1 button {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-color: #02365f;
  border-color: #02365f !important;
  height: 29px !important;
  width: 70px !important;
}

.butns1 button:hover,
.butns1 button:focus,
.butns1 button:active {
  background-color: #02365f !important;
  border-color: #02365f !important;
  outline-color: 0 !important;
}

.bms button {
  background-color: #02365f;
  border-color: #02365f !important;
}

.bms button:hover,
.bms button:focus,
.bms button:active {
  background-color: #02365f !important;
  border-color: #02365f !important;
  outline-color: 0 !important;
}

tbody tr .ttdata {
  padding-top: 5% !important;
  font-weight: bold;
}

.Buy_now_anchor {
  // float: right;
  // background-color: orange !important;
  //color:#fff !important;
  //text-decoration:underline;
  font-weight: normal !important;
  // background-image: url(../Assets/Images/unlockkol.svg);
  // background-image: url('../Assets/Images/bg_kol.jpg');
  //background-color: #41A2FF !important;

  background-image: url("../Assets/Images/lockkol.svg");
  background-position: 20px;

  background-repeat: no-repeat;
  background-size: 24%;
  font-size: 12px;
  padding-left: 50%;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1px solid #000;
  border-radius: 50px;
  //background-color: #ddd;
}

.Buy_now_anchor:hover {
  // float: right;
  font-weight: bolder !important;
  background-image: url("../Assets/Images/unlockkol.svg");
  text-decoration: underline;
  padding-left: 50%;
  padding-top: 18px;
  padding-bottom: 18px;
}

.lock_anchor {
  //float: left;
  //font-weight:bolder !important;
  background-image: url("../Assets/Images/lockkol.svg");
  display: inline-block;
  // position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 35px !important;
  height: 35px !important;
}

.lock_anchor:hover {
  background-image: url("../Assets/Images/unlockkol.svg");
}

.Quotblanck {
  font-size: 12px;
  color: red;
}

.Radio_button {
  width: auto;
  min-width: 30%;
  font-size: 15px;
  //text-decoration:underline;
  // font-weight: normal !important;
  display: inline-block;
  padding-left: 5px;
  margin-top: -25px;
}

.get_a_quote_anchor {
  color: #fff !important;
  text-decoration: underline;
  font-weight: normal !important;
}

.get_a_quote_anchor:hover {
  color: #fff !important;
  text-decoration: underline;
  font-weight: bold !important;
}

*:focus {
  outline: 0 !important;
}

.mlselect,
.mlselect:hover,
.mlselect:active,
.mlselect:focus {
  border-top: none !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.gbck {
  float: right !important;
  color: black !important;
  // font-size: 11px !important;
  margin-top: -2px !important;
}

.gbck a {
  text-decoration: none !important;
  font-weight: bold !important;
}

.arrow {
  position: relative;
    top: 50%;
    margin-top: -2px;
    left: 25px !important;
    width: 0;
    height: 0;
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    border-top: 6px solid #555 !important;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

// Summary Page

.navbar-nav {
  padding-right: 0px;
}

.navbar-nav .nav-link1 {
  font-size: 15px !important;
  font-weight: 600 !important;
  min-width: 25px !important;
  margin-top: 28px !important;
  color: black !important;
  margin-right: 10px !important;
  //   //padding-right: 20px;
  //   font-size: 12px !important;
  //   font-weight: bold;
  //  // padding-right: 25px !important;
}

.navbar-nav button,
.navbar-nav button:hover {
  color: white !important;
  background-color: #A51E22;
}

.navbar-nav button:hover {
  color: white !important;
  background-color: #A51E22;
}

.navbar-nav a:hover {
  color: red;
}

.nav-item .nav-link {
  font-size: 14px !important;
  color: black !important;
  background-color: transparent !important;
  font-weight: 600 !important;
  width: auto !important;
  //min-width: 150px !important;
  //max-width: 10px !important;

  //min-width: 105% !important;
  //max-width: 105% !important;
  //display:block;
}

.nav-item .active,
.nav_sm_itm .active,
.nav_sm_itm .sum_link:active {
  color: white !important;
  background-color: #A51E22 !important;
}

.nav-item .nav-linkss:focus,
.nav-item .nav-linkss:active,
.nav-item .nav-linkss:hover {
  color: white !important;
  background-color: #A51E22 !important;
}

.nav_sm_itm .sum_link:focus,
.nav_sm_itm .sum_link:hover {
  background-color: transparent !important;
  border: 1px solid #A51E22 !important;
  color: black !important;
}

.hdlead li {
  list-style: none !important;
  font-weight: normal !important;
  //height:auto;
  // min-height: 15px;
  margin-top: 2px !important;
  font-size: 14px !important;
}

.hdlead h6 {
  list-style: none !important;
  //font-size: 14px !important;
  margin-top: -3px;
}

.hdlead-btn {
  color: #A51E22 !important;
  border-color: #A51E22 !important;
  border-radius: 50px !important;
  width: 100px;
}

.hdlead-btn:hover {
  color: #fff !important;
  background-color: #A51E22 !important;
  border-color: #A51E22 !important;
}

.blurimage {
  // height:auto;
  // min-height: 600px;
  // margin-top: -30px;
  // margin-bottom: 20px;
  text-align: center !important;
  width: 100% !important;
  // background-image: url("../Assets/Images/bg_kol.jpg");
  height: 250px;
  // padding-top: 200px;
  // padding-bottom: 200px;
  //z-index: 99 !important;
  position: relative;

  filter: blur(1px);
  -webkit-filter: blur(4px);
  /* Full height */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blurimage_button {
  // height:auto;
  // min-height: 600px;
  margin-top: -140px;
  //margin-bottom: 20px;
  text-align: center !important;
  width: 100% !important;
  //  background-color: olivedrab !important;
  // height: 70px;
  // padding-top: 200px;
  // padding-bottom: 200px;
  //position: relative !important;
  z-index: 99 !important;
}

.prflpge {
  height: 95px !important;
  width: 95px !important;
  border-radius: 5px !important;

  //background-color: orange !important;
}

.client_dropdown {
  color: black !important;
  font-weight: 500;
  background-color: white !important;
  border: none !important;
}


.align-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px !important;
}

.client_dropdown:focus,
.client_dropdown:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.client_dropdown_item:hover {
  background: #A51E22 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.client_dropdown_item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.client_profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px dotted #A51E22;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../Assets/Images/default-user.jpeg");
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
}

.ratingbg {
  // height: 95px !important;
  // width: 95px !important;
  // background-color: #A51E22 !important;
  // border-color: #A51E22 !important;
  // color: white !important;
  float: right !important;
  font-weight: bold !important;
  font-size: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tbpnsd {
  margin-left: -23px;
  min-width: 105%;
}

.resmlnk {
  margin-top: -5px !important;
}

.pbio {
  border: 1px solid #044B6966;
  border-radius: 20px;
  padding: 4%;
  background-color: #D9D9D91A;
  min-height: 170px;
  height: 220px;
}

.pbio_table {
  border: 1px solid #044B6966;
  border-radius: 20px;
  padding: 4%;
  background-color: #D9D9D91A;
  // height: auto;
  // min-height: 170px;
  // height: 220px;
}

.pbio_head {
  font-weight: 700;
  margin-bottom: 5px;
  margin-left: -10px;
}

.pbio .biogr_li {
  list-style-type: none !important;
  font-size: 14px !important;
  text-decoration: none;
  margin-left: 20px;
}

.pbio .biogr_li a {
  text-decoration: none !important;
  font-size: 14px !important;
  color: #41a2ff !important;
}

.dtlpge p {
  font-size: 14px !important;
}

.dtlpge p li {
  font-size: 14px !important;
}

.dtlpge p li,
.dtlpge p li a {
  font-size: 14px !important;
}

.scrl_cntnt {
  overflow: auto;
  height: 425px;
}

.client-list-group .list-group-item {
  padding: 7px;
}

.trend-heading {
  width: 100%;
  text-align: center;
  color: #035B96;
  font-size: 25px;
}

.scrl_cntnt hr {
  height: 0.2px !important;
  margin-bottom: 2% !important;
}

/* width */
.scrl_cntnt::-webkit-scrollbar {
  width: 6px;
  cursor: pointer !important;
}

/* Track */
.scrl_cntnt::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle */
.scrl_cntnt::-webkit-scrollbar-thumb {
  background: #A51E22;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle on hover */
.scrl_cntnt::-webkit-scrollbar-thumb:hover {
  background: #A51E22;
  cursor: pointer !important;
}

.crd_menu_des {
  overflow: auto;
  width: 190px;
  //min-width: 195px;
  height: 569px;
}

/* width */
.crd_menu_des::-webkit-scrollbar {
  width: 6px;
  cursor: pointer !important;
}

/* Track */
.crd_menu_des::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle */
.crd_menu_des::-webkit-scrollbar-thumb {
  background: #A51E22;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle on hover */
.crd_menu_des::-webkit-scrollbar-thumb:hover {
  background: #A51E22;
  cursor: pointer !important;
}

.table_srls {
  overflow: auto;
  height: 335px !important;
}

/* width */
.table_srls::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
  cursor: pointer !important;
}

/* Track */
.table_srls::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle */
.table_srls::-webkit-scrollbar-thumb {
  background: #A51E22;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle on hover */
.table_srls::-webkit-scrollbar-thumb:hover {
  background: #A51E22;
  cursor: pointer !important;
}

.table_srl {
  overflow: auto;
  height: 400px !important;
}

/* width */
.table_srl::-webkit-scrollbar {
  width: 6px !important;
  cursor: pointer !important;
}

/* Track */
.table_srl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle */
.table_srl::-webkit-scrollbar-thumb {
  background: #A51E22;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle on hover */
.table_srl::-webkit-scrollbar-thumb:hover {
  background: #A51E22;
  cursor: pointer !important;
}

.scrl_cntnts {
  overflow: auto;
  // height: 379px;
}

/* width */
.scrl_cntnts::-webkit-scrollbar {
  width: 6px;
  cursor: pointer !important;
}

/* Track */
.scrl_cntnts::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle */
.scrl_cntnts::-webkit-scrollbar-thumb {
  background: #A51E22;
  border-radius: 5px;
  cursor: pointer !important;
}

/* Handle on hover */
.scrl_cntnts::-webkit-scrollbar-thumb:hover {
  background: #A51E22;
  cursor: pointer !important;
}

.table_srls table thead {
  position: sticky !important;
  top: 0 !important;
}

.tblurllnk {
  text-decoration: none !important;
}

.table_srls table tbody tr {
  border-bottom: 1px solid #ddd;
}

.scrl_cntntss {
  min-height: 426px !important;
  max-height: 426px !important;
}

.scrl_cntntss hr {
  height: 0.1px !important;
  margin-bottom: 1% !important;
}

.ttdata1 {
  margin-top: 20px;
  // position: relative;
  // display: inline-block;
  font-weight: bold !important;
  // width: auto !important;
  min-width: 35px !important;
  //max-width: 80px !important;
  text-align: center !important;
  font-size: 12px !important;
  // border-color: #02365F !important;

  /* padding-left: '0px' !important; */

  /* 
 margin-left: '0px' !important; 
     background-color: orange; 
    
    margin-left: '50px' !important;
    padding-left: '50px' !important; */
}

.P_Client {
  margin-top: 20px;
  width: auto !important;
  // position: relative;
  // display: inline-block;
  //  font-weight: bold !important;
  // width: auto !important;
  // min-width: 35px !important;
  //max-width: 80px !important;
  // text-align: center !important;
  font-size: 15px !important;
  // border-color: #02365F !important;

  /* padding-left: '0px' !important; */
}

.badge_ps {
  display: inline-block;
  min-width: 16px;
  /* pixel unit */
  padding: 5px 10px;
  /* pixel unit */
  font-size: 90%;
  text-align: center;
  background: #1779ba;
  border-radius: 50px;
  color: #fefefe;
  margin-top: -100px;
}

.vr {
  padding: 0;
}

.per_btn {
  border: 1px solid #A51E22;
  border-radius: 50px;
  width: 100px;
  color: #A51E22;
  text-decoration: none;
}

.per_btn:hover {
  border: 1px solid #A51E22;
  background-color: #A51E22;
  border-radius: 50px;
  width: 100px;
  text-decoration: none;
  color: white !important;
}

.per_btn_a {
  color: #A51E22;
}

.per_btn_a:hover {
  color: white !important;
}